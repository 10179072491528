import { Input, Form, Button, Row, Col, Typography, Alert, Table, Radio, Divider } from 'antd';

const ExchangeSelection = ({ exchange, setExchange }) => {
    return (
        <Radio.Group onChange={(e) => setExchange(e.target.value)} value={exchange} style={{ marginBottom: '20px' }}>
            <Radio.Button value="okx">OKX</Radio.Button>
            <Radio.Button value="binance">Binance</Radio.Button>
            <Radio.Button value="bitget">Bitget</Radio.Button>
        </Radio.Group>
    );
};
export default ExchangeSelection;
