import React, { useState, useEffect } from 'react';
import { Table, Tabs, Button, Spin, Icon } from 'antd';
import axios from 'axios';
import {
    SyncOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    MinusCircleFilled,
    QuestionCircleOutlined
  } from '@ant-design/icons';
const { TabPane } = Tabs;

const OperationsTable = ({ keyProp }) => {
    const [operations, setOperations] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [statusFilter, setStatusFilter] = useState('0');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        fetchOperations(pagination.current, pagination.pageSize, statusFilter);
    }, [pagination.current, pagination.pageSize, statusFilter, keyProp]); // 添加 keyProp 到依赖数组中

    const fetchOperations = async (page, pageSize, status) => {
        try {
            const response = await axios.get(`http://127.0.0.1:5000/api/tasks?page=${page}&per_page=${pageSize}&status=${status}`);
            const data = response.data;
            setOperations(data.tasks);
            setPagination({
                current: data.current_page,
                pageSize: data.per_page,
                total: data.total
            });
        } catch (error) {
            console.error('Error fetching operations:', error);
        }
    };

    const handleTabChange = (key) => {
        setStatusFilter(key);
        setPagination({ current: 1, pageSize: pagination.pageSize, total: 0 });
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const handleCancel = () => {
        console.log("取消选中的任务：", selectedRowKeys);
    };

    const handleCancelAll = () => {
        console.log("取消所有选中的任务");
        setSelectedRowKeys([]); // 清空选中的行
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const operationColumns = [
        { title: '任务ID', dataIndex: 'id', key: 'id', align: 'center', width: 20 },
        { title: '批次ID', dataIndex: 'batch_id', key: 'batch_id', align: 'center', width: 200 },
        { title: '任务类型', dataIndex: 'task_type', key: 'task_type', align: 'center', width: 40 },
        { title: '交易所', dataIndex: 'exchange_type', key: 'exchange_type', align: 'center', width: 40 },
        { title: '钱包类型', dataIndex: 'wallet_type', key: 'wallet_type', align: 'center', width: 40 },
        { title: '钱包地址', dataIndex: 'wallet_address', key: 'wallet_address', align: 'center', width: 120 },
        { title: '状态', dataIndex: 'status', key: 'status', align: 'center', width: 40, render: (status) => status ? renderStatusLogo(status) : <Spin indicator={<SyncOutlined spin />} /> },
        { title: '提币金额', dataIndex: 'withdrawal_amount', key: 'withdrawal_amount', align: 'center', width: 60, render: (amount) => amount ? `${amount}` : <SyncOutlined spin style={{ fontSize: 24, color: '#faad14' }} title="进行中" /> },
        { title: '完成时间', dataIndex: 'completion_time', key: 'completion_time', align: 'center', width: 180, render: (time) => time ? time : <SyncOutlined spin style={{ fontSize: 24, color: '#faad14' }} title="进行中" /> },
        { title: '结果', dataIndex: 'remark', key: 'remark', align: 'center', width: 120, render: (status) => status ? status : <SyncOutlined spin style={{ fontSize: 24, color: '#faad14' }} title="进行中" /> },
    ];
    
    function renderStatusLogo(status) {
        switch (status) {
            case "0":  // 进行中
                return <SyncOutlined spin style={{ fontSize: 24, color: '#1890ff' }} title="等待" />;
            case "1":  // 进行中
                return <SyncOutlined spin style={{ fontSize: 24, color: '#faad14' }} title="进行中" />;
            case "2":  // 完成
                return <CheckCircleFilled style={{ fontSize: 24, color: '#52c41a' }} title="完成" />;
            case "3":  // 失败
                return <CloseCircleFilled style={{ fontSize: 24, color: '#f5222d' }} title="失败" />;
            case "4":  // 取消
                return <MinusCircleFilled style={{ fontSize: 24, color: '#d9d9d9' }} title="取消" />;
            default:  // 未知状态
                return <QuestionCircleOutlined style={{ fontSize: 24, color: '#595959' }} title="未知状态" />;
        }
    }
    

    
    return (
        <div>
            <Tabs defaultActiveKey="all" onChange={handleTabChange}>
                <TabPane tab="所有任务" key="99" />
                <TabPane tab="等待中" key="0" />
                <TabPane tab="进行中" key="1" />
                <TabPane tab="已完成" key="2" />
                <TabPane tab="失败" key="3" />
                <TabPane tab="已取消" key="4" />
            </Tabs>
            <div style={{ marginBottom: 16 }}>
                <Button type="primary" danger onClick={handleCancel}>取消选中提币任务</Button>
                <span style={{ margin: '0 8px' }}></span>
                <Button type="default" onClick={handleCancelAll}>取消全部选中提币任务</Button>
            </div>
            <Table
                rowKey='id'
                dataSource={operations}
                pagination={pagination}
                columns={operationColumns} 
                onChange={(pagination) => setPagination(pagination)}
                size="small"
                rowSelection={rowSelection}
            />
        </div>
    );
};

export default OperationsTable;
