import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Pagination, Input, Button, message } from 'antd';
import * as XLSX from 'xlsx';

const Wallets = () => {
    const [wallets, setWallets] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const [groupFilter, setGroupFilter] = useState('');

    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize);
    }, []);

    const fetchWallets = async (page, pageSize, group = '') => {
        const response = await axios.get(`http://127.0.0.1:5000/api/wallets?page=${page}&per_page=${pageSize}&group=${group}`);
        const data = response.data;
        setWallets(data.wallets);
        setPagination({
            current: data.current_page,
            pageSize: data.per_page,
            total: data.total
        });
    };

    const handlePageChange = (page, pageSize) => {
        fetchWallets(page, pageSize, groupFilter);
    };

    const handleGroupSearch = () => {
        fetchWallets(1, pagination.pageSize, groupFilter);
    };

    const handleGroupChange = e => {
        setGroupFilter(e.target.value);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                try {
                    await axios.post('http://127.0.0.1:5000/api/wallets/import', json, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    fetchWallets(pagination.current, pagination.pageSize, groupFilter);
                    message.success('Import successful');
                } catch (error) {
                    console.error('Error importing wallets:', error);
                    // 更详细的错误信息显示
                    if (error.response && error.response.data && error.response.data.message) {
                        message.error(`Import failed: ${error.response.data.message}`);
                    } else {
                        message.error('Import failed due to unexpected error');
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };
    

    const handleDownloadTemplate = () => {
        const templateData = [['序号','钱包类型','地址', '私钥', '备注', '分组', '交易所地址', 'ads环境', '代理IP']];
        const templateSheet = XLSX.utils.aoa_to_sheet(templateData);
        const templateWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(templateWorkbook, templateSheet, 'Wallets Template');
        XLSX.writeFile(templateWorkbook, 'wallets_template.xlsx');
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'ADS环境', dataIndex: 'ads_environment', key: 'ads_environment' },
        { title: '代理IP', dataIndex: 'proxy', key: 'proxy' },
        { title: '组', dataIndex: 'group', key: 'group' },
        { title: '钱包类型', dataIndex: 'wallet_type', key: 'wallet_type'},
        { title: '地址', dataIndex: 'address', key: 'address' },
        { title: '交易所地址', dataIndex: 'exchange_address', key: 'exchange_address' },
        { title: '备注', dataIndex: 'note', key: 'note' },
        { title: '创建时间', dataIndex: 'created_at', key: 'created_at' },
        { title: '更新时间', dataIndex: 'updated_at', key: 'updated_at' }
    ];

    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    <Input placeholder="Search by Group" value={groupFilter} onChange={handleGroupChange} style={{ width: 200, marginRight: 8 }} />
                    <Button onClick={handleGroupSearch}>Search</Button>
                </div>
                <div>
                    <Button style={{ marginLeft: 8 }} onClick={handleDownloadTemplate}>Download Template</Button>
                    <input type="file" onChange={handleFileChange} style={{ display: 'none' }} />
                    <Button type="primary" style={{ marginLeft: 8 }} onClick={() => document.querySelector('input[type="file"]').click()}>Import</Button>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={wallets}
                rowKey="id"
                pagination={false}
                style={{ marginBottom: 20 }}
            />
            <Pagination
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={handlePageChange}
            />
        </div>
    );
};

export default Wallets;
