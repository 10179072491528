import React, { useState, useEffect } from 'react';
import { Table, Tabs } from 'antd';
import axios from 'axios';
import './WalletsTable.css';

const { TabPane } = Tabs;

const WalletsTable = ({ rowSelection }) => {
    const [wallets, setWallets] = useState([]);
    const [walletTypes, setWalletTypes] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [activeTab, setActiveTab] = useState("All");

    useEffect(() => {
        fetchWalletTypes();
    }, []);

    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize, activeTab);
    }, [pagination.current, pagination.pageSize, activeTab]);

    const fetchWalletTypes = async () => {
        const response = await axios.get('http://127.0.0.1:5000/api/wallet_types');
        setWalletTypes(response.data.wallet_types);
    };
    const handleTabChange = (key) => {
        setActiveTab(key);
        setPagination({ ...pagination, current: 1 });  // 重置到第一页
        fetchWallets(1, pagination.pageSize, key);      // 重新获取数据
    };
    const fetchWallets = async (page, pageSize, type) => {
        const url = type === "All" ?
            `http://127.0.0.1:5000/api/wallets?page=${page}&per_page=${pageSize}` :
            `http://127.0.0.1:5000/api/wallets?type=${type}&page=${page}&per_page=${pageSize}`;
        const response = await axios.get(url);
        const data = response.data;
        setWallets(data.wallets);
        setPagination({
            current: data.current_page,
            pageSize: data.per_page,
            total: data.total
        });
    };

    const columns = [
        { title: 'ADS环境', dataIndex: 'ads_environment', key: 'ads_environment', align: 'center', width: 10 },
        { title: '组', dataIndex: 'group', key: 'group', align: 'center', width: 30 },
        { title: '钱包类型', dataIndex: 'wallet_type', key: 'wallet_type', align: 'center', width: 30 },
        { title: '地址', dataIndex: 'address', key: 'address', align: 'center', width: 200 },
        { title: '代理IP', dataIndex: 'proxy', key: 'proxy', align: 'center', width: 200 },
    ];

    return (
        <Tabs defaultActiveKey="All" onChange={handleTabChange} >
            <TabPane tab="All" key="All">
                <Table
                    rowKey='id'
                    dataSource={wallets}
                    rowSelection={rowSelection}
                    pagination={{
                        ...pagination,
                        className: 'pagination-center',
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
                    }}
                    columns={columns}
                    onChange={(pagination) => setPagination(pagination)}
                    size="small"
                />
            </TabPane>
            {walletTypes.map(type => (
                <TabPane tab={type} key={type}>
                    <Table
                        rowKey='id'
                        dataSource={wallets}
                        rowSelection={rowSelection}
                        pagination={{
                            ...pagination,
                            className: 'pagination-center',
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '30', '40', '50', '100', '200'],
                        }}
                        columns={columns}
                        onChange={(pagination) => setPagination(pagination)}
                        size="small"
                    />
                </TabPane>
            ))}
        </Tabs>
    );
};

export default WalletsTable;
