import { Input, Form, Button, Row, Col, Typography, Alert, Table, Radio, Divider } from 'antd';

const AlertMessage = () => {
    return (
        <Alert
            message="重要提示"
            description="比如把钱包地址加入白名单: 同时出口IP设置为: 192.168.0.01"
            type="warning"
            showIcon
            style={{ marginBottom: '20px' }}
        />
    );
};
export default AlertMessage;
