import React, { useState } from 'react';
import { Form, Button, Divider, message } from 'antd';
import axios from 'axios';
import './ExchangeWithdrawal.css';
import ExchangeSelection from './ExchangeSelection';
import ExchangeFormFields from './ExchangeFormFields';
import WalletsTable from './WalletsTable';
import OperationsTable from './OperationsTable';
import AlertMessage from './AlertMessage';

const ExchangeWithdrawal = () => {
    const [formData, setFormData] = useState({
        exchange: 'okx',
        ApiKey: '',
        SecretKey: '',
        Password: '',
        tokenName: '',
        network: '',
        startTime: '',
        endTime: '',
        startAmount: '',
        endAmount: '',
        decimals: '',
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [reloadTable, setReloadTable] = useState(false); // 声明并初始化 'reloadTable' 变量

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRowSelectionChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const handleSubmit = async () => {
        const selectedWalletIDs = selectedRowKeys; // 使用已选中的行的键值作为钱包ID
        console.log('Selected Wallet IDs:', selectedWalletIDs);

        const requestData = {
            exchange: formData.exchange,
            ApiKey: formData.ApiKey,
            SecretKey: formData.SecretKey,
            Password: formData.Password,
            tokenName: formData.tokenName,
            fee:formData.fee,
            network: formData.network,
            startTime: formData.startTime,
            endTime: formData.endTime,
            startAmount: formData.startAmount,
            endAmount: formData.endAmount,
            decimals: formData.decimals,
            walletid: selectedWalletIDs.map(id => ({ id })) // 将选中的钱包ID构建成对象数组
        };

        console.log('Submitted Data:', requestData);

        try {
            const response = await axios.post('http://127.0.0.1:5000/api/tasks/batch', requestData);
            console.log('Response from server:', response.data);
            // 提示成功消息给用户
            message.success('任务提交成功！');
            // 在此处可以处理服务器的响应
        } catch (error) {
            console.error('Error submitting data:', error);
            // 提示失败消息给用户
            message.error('任务提交失败，请稍后重试！');
            // 在此处可以处理错误情况
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <AlertMessage />
            <ExchangeSelection exchange={formData.exchange} setExchange={(value) => setFormData({ ...formData, exchange: value })} />
            <Form layout="vertical">
                <Button type="primary" onClick={handleSubmit} style={{ marginBottom: '20px' }}>提交任务</Button>
                <ExchangeFormFields exchange={formData.exchange} includePassword={formData.exchange === 'okx' || formData.exchange === 'bitget'} formData={formData} onInputChange={handleInputChange} />
            </Form>
            <WalletsTable rowSelection={{ onChange: handleRowSelectionChange, selectedRowKeys }} />
            <Divider>运行状态</Divider>
            <OperationsTable key={reloadTable ? 'reload' : 'no-reload'} /> {/* 使用 key 属性控制重新加载表格 */}

        </div>
    );
};

export default ExchangeWithdrawal;
