import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { AppstoreOutlined, WalletOutlined, DownOutlined } from '@ant-design/icons';
import './App.css'; // 引入样式文件

// Import your page components
import Wallet from '../src/components/Wallet';
import ExchangeWithdrawal from './components/exchange/ExchangeWithdrawal';
import OtherComponent from '../src/components/OtherComponent';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

// Navigation items definition
const navItems = [
  {
    label: '钱包管理',
    key: 'home',
    icon: <AppstoreOutlined />,
    path: "/",
    component: Wallet
  },
  {
    label: '交易所提币',
    key: 'exchange',
    icon: <AppstoreOutlined />,
    path: "/exchange",
    component: ExchangeWithdrawal
  },
  {
    label: '钱包管理',
    key: 'wallet-management',
    icon: <WalletOutlined />,
    subItems: [
      {
        label: '交易所提币',
        key: 'exchange-withdrawal',
        path: "/exchange-withdrawal",
        component: ExchangeWithdrawal
      },
      {
        label: '其他',
        key: 'other',
        path: "/other",
        component: OtherComponent
      }
    ]
  }
];

// Navigation Menu Component
const NavigationMenu = ({ current, handleClick }) => (
  <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
    {navItems.map(item => item.subItems ? (
      <SubMenu key={item.key} icon={item.icon} title={item.label}>
        {item.subItems.map(subItem => (
          <Menu.Item key={subItem.key}>
            <NavLink to={subItem.path}>{subItem.label}</NavLink>
          </Menu.Item>
        ))}
      </SubMenu>
    ) : (
      <Menu.Item key={item.key} icon={item.icon}>
        <NavLink to={item.path}>{item.label}</NavLink>
      </Menu.Item>
    ))}
  </Menu>
);

const App = () => {
    const [current, setCurrent] = useState('home');
  
    const handleClick = e => setCurrent(e.key);
  
    return (
      <Router>
        <Header className="header">
          <NavigationMenu current={current} handleClick={handleClick} className="menu" />
        </Header>
        <Content className="content">
          <Routes>
            {navItems.flatMap(item => item.subItems ? item.subItems.map(subItem => (
              <Route key={subItem.path} path={subItem.path} element={<subItem.component />} />
            )) : (
              <Route key={item.path} path={item.path} element={<item.component />} />
            ))}
          </Routes>
        </Content>
      </Router>
    );
  };
  
  export default App;
