import React, { useState } from 'react';
import { Input, Select, Button, Typography, message } from 'antd';
import axios from 'axios';

const { Option } = Select;
const { Text } = Typography;



const ExchangeFormFields = ({ exchange, includePassword, formData, onInputChange }) => {
    const apiKeyField = `ApiKey`;
    const secretKeyField = `SecretKey`;
    const passwordField = `Password`;
    const [options, setOptions] = useState([]);
    const [allCoin, setAllCoin] = useState([]);
    const [optionsnet, setOptionsNet] = useState([]);
    const handleSelectChangeNetWork = (value) => {
        onInputChange({ target: { name: 'network', value } });
    };
    const handleSelectChangeTokenName = (value) => {
        onInputChange({ target: { name: 'tokenName', value } });
        console.log(value)
        const networks = allCoin[value] || [];

        const networkOptions = networks.map(network => ({
            label: `${network.Network} - 可提: ${network.CanWithdraw ? '是' : '否'}, fee: ${network.Fee}`,
            value: network.Network,
            disabled: !network.CanWithdraw  // 如果 CanWithdraw 不是 true，则不可选
        }));
        setOptionsNet(networkOptions);

        console.log(networkOptions);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };
    const fetchOptions = async () => {
        const { ApiKey, SecretKey, Password } = formData;
        if (!ApiKey || !SecretKey) {
            message.error('Please provide all required credentials.');
            return;
        }

        try {
            const response = await axios.get(`http://127.0.0.1:5000/api/exchange/currency`, {
                params: {
                    exchange_name: exchange,
                    ApiKey,
                    SecretKey,
                    Password
                }
            });
            setAllCoin(response.data)
            const dataOptions = Object.keys(response.data).map(key => ({
                label: key, // 代币名称，如 "1INCH"
                value: key // 相关的网络信息列表
            }));
            setOptions(dataOptions);
            message.success('Token information successfully loaded.');
        } catch (error) {
            // 提取并显示详细的错误信息
            const errorMessage = error.response?.data?.description || error.response?.data?.message || error.message;
            message.error('Failed to fetch options: ' + errorMessage);
        }
    };


    const filterOption = (input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase());
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '-8px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', margin: '8px' }}>
                <div style={{ marginRight: '16px' }}>
                    <Text>APIKEY：</Text>
                    <Input name={apiKeyField} placeholder={`输入 ${exchange} API密钥`} value={formData[apiKeyField]} onChange={onInputChange} style={{ width: '200px' }} required  />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>密钥：</Text>
                    <Input name={secretKeyField} placeholder={`输入 ${exchange} 密钥`} value={formData[secretKeyField]} onChange={onInputChange} style={{ width: '200px' }} required  />
                </div>
                {includePassword && (
                    <div style={{ marginRight: '16px' }}>
                        <Text>密码：</Text>
                        <Input.Password name={passwordField} placeholder={`输入 ${exchange} 密码`} value={formData[passwordField]} onChange={onInputChange} style={{ width: '200px' }} required  />
                    </div>
                )}
                <div style={{ marginRight: '16px' }}>
                    <Button type="primary" onClick={fetchOptions} style={{ marginRight: '16px' }}>查询代币网络信息</Button>
                    <Text>代币名称：</Text>
                    <Select
                        showSearch
                        style={{ minWidth: '100px' }}
                        placeholder="输入代币名称"
                        value={formData.tokenName} // Ensure the selected value is controlled by React state
                        onChange={handleSelectChangeTokenName} // Update state on change
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={options}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-end', margin: '8px' }}>
                <div style={{ marginRight: '16px' }}>
                    <Text>提币网络:</Text>
                    <Select
                        showSearch
                        style={{ minWidth: '300px' }}
                        placeholder="输入网络"
                        optionFilterProp="children"
                        value={formData.network} // Ensure the selected value is controlled by React state
                        onChange={handleSelectChangeNetWork} // Update state on change
                        filterOption={filterOption}
                        options={optionsnet}
                    />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>间隔最低时间：</Text>
                    <Input name="startTime" type="number" placeholder="随机最低时间(秒)" value={formData.startTime} onChange={onInputChange} style={{ width: '150px' }} required   />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>间隔最高时间：</Text>
                    <Input name="endTime" type="number" placeholder="随机最高时间(秒)" value={formData.endTime} onChange={onInputChange} style={{ width: '150px' }} required  />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>开始金额：</Text>
                    <Input type="number" name="startAmount" placeholder="开始金额" value={formData.startAmount} onChange={onInputChange} style={{ width: '100px' }} required  />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>结束金额：</Text>
                    <Input type="number" name="endAmount" placeholder="结束金额" value={formData.endAmount} onChange={onInputChange} style={{ width: '100px' }} required  />
                </div>
                <div style={{ marginRight: '16px' }}>
                    <Text>小数位数：</Text>
                    <Input type="number" name="decimals" placeholder="输入小数位数" value={formData.decimals} onChange={onInputChange} style={{ width: '150px' }} required  />
                </div>
            </div>
        </div>
    );
};

export default ExchangeFormFields;
